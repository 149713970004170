import 'ol/ol.css';
import 'ol-ext/dist/ol-ext.css';
import 'font-awesome/css/font-awesome.min.css';
import 'jquery-ui-bundle/jquery-ui.css';

import './index.scss';

import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import {
  defaults as defaultInteractions,
  DragRotateAndZoom,
} from 'ol/interaction';
import { defaults as defaultControls, Rotate, ScaleLine } from 'ol/control';
import { fromLonLat } from 'ol/proj';
import Overlay from 'ol/Overlay';
import {
  LineString,
  MultiLineString,
  MultiPoint,
  LinearRing,
  MultiPolygon,
  Point,
  Polygon,
} from 'ol/geom';

import Toggle from 'ol-ext/control/Toggle';
import LayerSwitcher from 'ol-ext/control/LayerSwitcher';
import { default as OlExtOverlay } from 'ol-ext/control/Overlay';
import Dialog from 'ol-ext/control/Dialog';
import Button from 'ol-ext/control/Button';
import Bar from 'ol-ext/control/Bar';
import TextButton from 'ol-ext/control/TextButton';

import OL3Parser from 'jsts/org/locationtech/jts/io/OL3Parser';
import RelateOp from 'jsts/org/locationtech/jts/operation/relate/RelateOp';

import shp from 'shpjs';

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import axios from 'axios';

import {
  znieff1Layer,
  zpsLayer,
  sicLayer,
  selectedLayer,
  userLayer,
  layerMinZoom,
} from './layers';
import { kmlSrc, geojsonSrc4326, geojsonSrc2154 } from './sources';
import { getSiteId, getSiteName } from './utils';
import { getHighlightStyle } from './styles';
import { proj3857 } from './proj';

// Handle jQuery/jQuery UI global variables with parcel
const $ = require('jquery');
window.$ = window.jQuery = $;
require('jquery-ui-bundle');

new (class {
  constructor() {
    // PDF export base parameters
    this.imgWidth = 718;
    this.imgHeight = 378;
    this.contentWidth = 170;
    this.horizontalMargin = 20;
    this.mapHeight = 100;

    this.menuOverlay = new OlExtOverlay({
      closeBox: true,
      className: 'slide-left menu',
      content: $('#menu').get(0),
    });

    this.baseDialogButtons = { submit: 'Ok !', cancel: 'Annuler' };

    this.locationDialog = new Dialog({ className: 'location-dialog' });

    this.uploadDialog = new Dialog({ className: 'upload-dialog' });

    this.exportDialog = new Dialog();

    this.view = new View({
      // Center coordinates of EPSG:2154 transformed to EPSG 3857
      center: [28943.07, 5837421.87],
      zoom: 6,
    });

    this.inpnLayers = [znieff1Layer, sicLayer, zpsLayer];

    this.jstsParser = new OL3Parser();
    this.jstsParser.inject(
      Point,
      LineString,
      LinearRing,
      Polygon,
      MultiPoint,
      MultiLineString,
      MultiPolygon,
      Polygon
    );

    // Map export button
    this.exportMapButton = new Button({
      html: "<i class='fa fa-download'></i>",
      className: 'export-map-button',
      title: 'Export PDF',
      handleClick: () => {
        this.exportPdf();
      },
    });

    this.exportMapButton.setVisible(false);

    this.map = new Map({
      target: 'map',
      layers: [
        new TileLayer({
          source: new OSM(),
          displayInLayerSwitcher: false,
        }),
        userLayer,
        ...this.inpnLayers,
        selectedLayer,
      ],
      interactions: defaultInteractions({
        shiftDragZoom: false,
        altShiftDragRotate: false,
        pinchRotate: false,
        doubleClickZoom: false,
      }).extend([new DragRotateAndZoom()]),
      controls: defaultControls({
        zoomOptions: {
          zoomInTipLabel: 'Zoomer',
          zoomOutTipLabel: 'Dézoomer',
        },
      }).extend([
        new ScaleLine(),
        new Rotate({
          autoHide: false,
          tipLabel: 'Annuler la rotation',
        }),
        new LayerSwitcher({ reordering: false }),
        this.menuOverlay,
        this.exportMapButton,
        // Search location button
        new Button({
          html: "<i class='fa fa-search'></i>",
          className: 'search-location-button',
          title: 'Recherche',
          handleClick: () => {
            this.locationDialog.show();
          },
        }),
        // Menu toggle button
        new Toggle({
          html: '<i class="fa fa-bars" ></i>',
          className: 'menu',
          title: 'Menu',
          onToggle: () => {
            if (!this.selectedFeatByClick) {
              this.hvcMenu.html(
                '<p>Cliquer sur un zonage pour accéder à son descriptif.</p>'
              );
            }

            this.menuOverlay.toggle();
          },
        }),
        this.locationDialog,
        this.uploadDialog,
        this.exportDialog,
      ]),
      view: this.view,
    });

    this.initDialogs();
    this.initSearchLocationAutocomplete();

    this.importBtn = new Toggle({
      title: 'Imports données géographiques',
      html: "<i class='fa fa-upload'></i>",
      bar: new Bar({
        toggleOne: true,
        controls: [
          new TextButton({
            html: 'KML',
            handleClick: () => {
              this.uploadDialog.show({
                title: 'Import KML (EPSG:4326)',
                content: "<input id='kml-file' type='file' accept='.kml'>",
                buttons: this.baseDialogButtons,
              });
              this.readAndDisplayUserGeoData('kml', kmlSrc);
            },
          }),
          new TextButton({
            html: 'GeoJSON',
            handleClick: () => {
              this.uploadDialog.show({
                title: 'Import GeoJSON (EPSG:4326)',
                content:
                  "<input id='geojson-file' type='file' accept='.geojson'>",
                buttons: this.baseDialogButtons,
              });
              this.readAndDisplayUserGeoData('geojson', geojsonSrc4326);
            },
          }),
          new TextButton({
            html: 'SHP',
            handleClick: () => {
              this.uploadDialog.show({
                title: 'Import SHP (EPSG:2154)',
                content: "<input id='shp-file' type='file' accept='.shp'>",
                buttons: this.baseDialogButtons,
              });
              this.readAndDisplayUserGeoData('shp', geojsonSrc2154);
            },
          }),
        ],
      }),
    });

    const uploadBar = new Bar({
      title: 'Import',
      className: 'upload-bar',
      controls: [this.importBtn],
    });

    uploadBar.setPosition('left');
    this.map.addControl(uploadBar);

    this.initMapListeners();

    this.hvcMenu = $('#hvc-menu');

    this.loadHvcLogo();

    $(document).ready(() => {
      this.map.updateSize();
    });
  }

  initDialogs() {
    this.locationDialog.setContent({
      title: 'Se localiser',
      content:
        "<input id='search-location' placeholder='Rechercher un lieu, une adresse, une commune'>",
      buttons: this.baseDialogButtons,
    });

    this.locationDialog.show();
    this.locationDialog.on('button', (e) => {
      if (e.button === 'submit' && this.selectLocationInfos) {
        const [selectLocationGeom, isMunicipality] = this.selectLocationInfos;
        this.view.animate({
          center: fromLonLat(selectLocationGeom.coordinates),
          zoom: Math.max(this.view.getZoom(), isMunicipality ? 12 : 16),
        });
      }
    });

    this.uploadDialog.on('show', () => {
      if (!this.submitUploadButton) {
        this.submitUploadButton = $(
          '.upload-dialog .ol-buttons input[type=submit]'
        );
      }

      this.submitUploadButton.hide();
    });

    this.uploadDialog.on('button', (e) => {
      const layerSrc = userLayer.getSource();

      const layerFeats = layerSrc ? layerSrc.getFeatures() : [];

      if (e.button === 'submit' && layerFeats.length > 0) {
        if (!userLayer.getVisible()) {
          userLayer.setVisible(true);
        }

        this.view.fit(layerSrc.getExtent(), {
          padding: [50, 50, 50, 50],
          duration: 1000,
        });

        this.exportMapButton.setVisible(
          ['Polygon', 'MultiPolygon'].includes(
            layerFeats[0].getGeometry().getType()
          )
        );
      } else if (e.button === 'cancel' && layerSrc) {
        layerSrc.clear();

        this.exportMapButton.setVisible(false);

        if (userLayer.getVisible()) {
          userLayer.setVisible(false);
        }
      }

      this.submitUploadButton.hide();
      this.importBtn.toggle();
    });
  }

  initSearchLocationAutocomplete() {
    const submitSearchButton = $(
      '.location-dialog .ol-buttons input[type=submit]'
    );
    submitSearchButton.hide();
    const searchLocation = $('#search-location');
    searchLocation.autocomplete({
      minLength: 0,
      select: (event, ui) => {
        event.preventDefault();
        const item = ui.item;
        this.selectLocationInfos = JSON.parse(item.value);

        searchLocation.val(item.label);

        if (!submitSearchButton.is(':visible')) {
          submitSearchButton.show();
        }
      },
      focus: (event) => {
        event.preventDefault();
      },
      source: (request, response) => {
        if (this.selectLocationInfos) {
          this.selectLocationInfos = null;
        }

        const search = request.term;

        if (search === '') {
          if (submitSearchButton.is(':visible')) {
            submitSearchButton.hide();
          }
        } else {
          axios
            .get('https://api-adresse.data.gouv.fr/search', {
              params: { q: search, limit: 25 },
            })
            .then((res) => {
              response(
                res.data.features.map((feat) => {
                  let label = feat.properties['label'];

                  const isMunicipality =
                    feat.properties['type'] === 'municipality';

                  if (isMunicipality) {
                    label += ` (${feat.properties['postcode']})`;
                  }

                  return {
                    label: label,
                    value: JSON.stringify([feat.geometry, isMunicipality]),
                  };
                })
              );
            })
            .catch((error) => {
              console.log(error);
            });
        }
      },
    });
  }

  readAndDisplayUserGeoData(format, layerSrc) {
    $(`#${format}-file`).change((e) => {
      layerSrc.clear();
      userLayer.setVisible(false);

      const filesArr = $(e.target).prop('files');

      if (filesArr.length > 0) {
        const [file] = filesArr;
        const reader = new FileReader();

        reader.onload = () => {
          let features = reader.result;
          if (format === 'shp') {
            features = shp.combine([shp.parseShp(features), []]);
          }

          layerSrc.addFeatures(
            layerSrc
              .getFormat()
              .readFeatures(features, { featureProjection: proj3857 })
          );

          userLayer.setSource(layerSrc);
          this.submitUploadButton.show();
        };

        if (format === 'shp') {
          reader.readAsArrayBuffer(file);
        } else {
          reader.readAsText(file);
        }
      }
    });
  }

  displayFscItems(itemType, itemArr, itemArrName) {
    const accordionId = `accordion-${itemType}`;
    const tableContainerId = `panel-${itemType}`;
    if (itemArr.length === 0) {
      $(`#${tableContainerId}, #${accordionId}`).html('');
    } else {
      const accordion = $(
        `<button id=${accordionId} class="accordion title-bg">${itemArrName}</button>`
      );
      const tableContainer = $(
        `<div id=${tableContainerId} class="table-container"></div>`
      );
      this.hvcMenu.append(accordion);
      const table = $('<table></table>');

      let getItemRow;

      if (itemType === 'species') {
        table.append(
          '<tr><th>Nom scientifique</th><th>Nom français</th><th>Priorité</th><th>Fiche</th></tr>'
        );
        getItemRow = (item) => {
          const sheetDlIcon = item['sheet_filename']
            ? `<a href="${process.env.API_URL}/specie-sheet?filename=${item['sheet_filename']}" target="_blank"><i class="fa fa-download" aria-hidden="true"></i></a>`
            : '';

          return `
            <tr>
              <td style="font-style: italic">${item['name']}</td>
              <td>${item['vernacular_name'] ? item['vernacular_name'] : ''}</td>
              <td>${item['priority']}</td>
              <td>${sheetDlIcon}</td>
            </tr>
          `;
        };
      } else if (itemType === 'habitats') {
        getItemRow = (item) => `<tr><td>${item['lb_hab']}</td></tr>`;
      }

      for (let i = 0; i < itemArr.length; i++) {
        table.append(getItemRow(itemArr[i]));
      }

      tableContainer.append(table);
      tableContainer.hide();

      accordion.click(() => {
        accordion.toggleClass('is-active');
        if (tableContainer.is(':visible')) {
          tableContainer.hide();
        } else {
          tableContainer.show();
        }
      });

      this.hvcMenu.append(tableContainer);
    }
  }

  clearSelectFeatByHover() {
    if (this.selectedFeatByHover) {
      this.selectedFeatByHover.setStyle(undefined);
      this.selectedFeatByHover = null;
    }
  }

  initMapListeners() {
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    let tooltipElement, tooltip;

    this.map.on(['pointermove', 'singleclick'], (event) => {
      if (tooltip) {
        this.map.removeOverlay(tooltip);
      }

      if (tooltipElement) {
        if (tooltipElement.parentNode) {
          tooltipElement.parentNode.removeChild(tooltipElement);
        }
      }

      this.clearSelectFeatByHover();

      if (this.selectedFeatByClick && event.type === 'singleclick') {
        this.selectedFeatByClick = null;
        selectedLayer.getSource().clear();
        this.hvcMenu.html('');
      }

      if (this.view.getZoom() < layerMinZoom) {
        return;
      }

      // Select first feature found
      const featInfo = this.map.forEachFeatureAtPixel(
        event.pixel,
        (f, l) => [f, l],
        {
          layerFilter: (l) => this.inpnLayers.includes(l),
        }
      );

      if (!featInfo) {
        if (event.type === 'singleclick' && this.menuOverlay.getVisible()) {
          this.menuOverlay.hide();
        }
        return;
      }

      const [feature, layer] = featInfo;

      const siteName = getSiteName(feature);

      const layerName = layer.get('fullName');

      if (event.type === 'pointermove' && !isMobile) {
        if (getSiteId(feature) !== getSiteId(this.selectedFeatByClick)) {
          this.selectedFeatByHover = feature;
          tooltipElement = document.createElement('div');
          tooltipElement.className = 'ol-tooltip';
          tooltip = new Overlay({
            element: tooltipElement,
            offset: [15, 0],
            positioning: 'center-left',
            stopEvent: false,
          });
          this.map.addOverlay(tooltip);
          tooltipElement.innerHTML = `
            <b>${layerName}</b><br>
            ${siteName}
          `;

          tooltip.setPosition(event.coordinate);
          feature.setStyle(getHighlightStyle(layer, '33'));
        }
      } else if (event.type === 'singleclick') {
        const siteCode = getSiteId(feature);

        const sitePdfUrl = this.getSitePdfUrl(siteCode, layer);

        this.selectedFeatByClick = feature.clone();
        selectedLayer.getSource().addFeature(this.selectedFeatByClick);
        this.selectedFeatByClick.setStyle(
          getHighlightStyle(layer, 'CC', siteName)
        );

        if (!this.menuOverlay.getVisible()) {
          this.menuOverlay.toggle();
        }

        const mapPadding = 5;

        const featureExtent = feature.getGeometry().getExtent();

        this.view.fit(featureExtent, {
          padding: [
            mapPadding,
            mapPadding,
            mapPadding,
            parseInt($('.ol-overlay.menu').css('width').replace('px', ''), 10) +
              mapPadding,
          ],
        });

        this.hvcMenu.html(
          `
            <p>
              <b>Type : </b>${layerName}<br>
              <b>Nom : </b> ${siteName}<br>
              <b>Exporter la fiche INPN : </b><a href="${sitePdfUrl}" target="_blank"><i class="fa fa-download" aria-hidden="true"></i></a><br>
              <b>Exporter les données HVC : </b><a href="#" id="export-site-pdf"><i class="fa fa-download" aria-hidden="true"></i></a><br>
            </p>
          `
        );

        this.getFscItems(siteCode).then((res) => {
          this.displayFscItems(
            'species',
            res.data['species'],
            'Espèces prioritaires présentes'
          );

          this.displayFscItems(
            'habitats',
            res.data['habitats'],
            'Habitats présents'
          );

          $('#export-site-pdf').click(async () => {
            this.showExportLoader();

            const doc = new jsPDF({
              unit: 'mm',
            });
            const currentExtent = this.view.calculateExtent(this.map.getSize());
            const [currentWidth, currentHeight] = this.map.getSize();

            this.setMapSizeAndExtent(
              [this.imgWidth, this.imgHeight],
              featureExtent,
              { padding: [5, 5, 5, 5] }
            );

            await this.setSitePdfpage(doc, {
              ...res.data,
              feature: feature,
              layer: layer,
              layerName: layer.get('fullName'),
              sitePdfUrl: this.getSitePdfUrl(getSiteId(feature), layer),
              siteName: getSiteName(feature),
            });

            // Restore map size and view extent
            this.setMapSizeAndExtent(
              [currentWidth, currentHeight],
              currentExtent,
              {
                render: true,
              }
            );

            doc.save(`hvc_${siteName}_${new Date().toLocaleDateString()}.pdf`);

            this.exportDialog.hide();
          });
        });
      }
    });
  }

  getSitePdfUrl(siteCode, layer) {
    let sitePdfUrl = 'https://inpn.mnhn.fr/docs/';
    if (layer === znieff1Layer) {
      sitePdfUrl += 'ZNIEFF/znieffpdf/';
    } else {
      sitePdfUrl += 'natura2000/fsdpdf/';
    }
    sitePdfUrl += `${siteCode}.pdf`;
    return sitePdfUrl;
  }

  async getFscItems(siteCode) {
    return axios
      .get(`${process.env.API_URL}/species-habitats`, {
        params: { site_id: siteCode },
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async getUserInpnFeaturesInfos() {
    let intersectedInpnFeaturesInfos = [];

    const userLayerSrc = userLayer.getSource();

    if (userLayerSrc) {
      userLayerSrc.forEachFeature((userFeature) => {
        const jstsUserGeom = this.jstsParser.read(userFeature.getGeometry());

        this.inpnLayers.forEach((inpnLayer) => {
          inpnLayer.getSource().forEachFeature((inpnFeature) => {
            if (
              RelateOp.intersects(
                jstsUserGeom,
                this.jstsParser.read(inpnFeature.getGeometry())
              )
            ) {
              const inpnFeatureInfo = {
                feature: inpnFeature,
                layer: inpnLayer,
              };

              if (
                !intersectedInpnFeaturesInfos
                  .map((inpnFeatInfo) => inpnFeatInfo.feature)
                  .includes(inpnFeatureInfo.feature)
              ) {
                intersectedInpnFeaturesInfos.push(inpnFeatureInfo);
              }
            }
          });
        });
      });

      intersectedInpnFeaturesInfos = await Promise.all(
        intersectedInpnFeaturesInfos.map(async (inpnFeatureInfo) => {
          const res = await this.getFscItems(
            getSiteId(inpnFeatureInfo.feature)
          );
          return {
            ...res.data,
            ...inpnFeatureInfo,
            layerName: inpnFeatureInfo.layer.get('fullName'),
            sitePdfUrl: this.getSitePdfUrl(
              getSiteId(inpnFeatureInfo.feature),
              inpnFeatureInfo.layer
            ),
            siteName: getSiteName(inpnFeatureInfo.feature),
          };
        })
      );
    }

    return intersectedInpnFeaturesInfos;
  }

  showExportLoader() {
    this.exportDialog.show({
      content:
        '<div id="loader-container"><span id="loader-text">Export en cours</span> <div id="loader"></div></div>',
      closeBox: true,
    });
  }

  async exportPdf() {
    this.showExportLoader();

    this.clearSelectFeatByHover();

    const userInpnFeaturesInfos = (await this.getUserInpnFeaturesInfos()).sort(
      (a, b) => b.layerName - a.layerName
    );

    if (userInpnFeaturesInfos.length === 0) {
      this.exportDialog.show({
        content: "Le contour de la forêt n'intersecte aucun zonage.",
        closeBox: true,
      });
      return;
    }

    const currentExtent = this.view.calculateExtent(this.map.getSize());
    const [currentWidth, currentHeight] = this.map.getSize();

    this.setMapSizeAndExtent(
      [this.imgWidth, this.imgHeight],
      userLayer.getSource().getExtent(),
      { padding: [5, 5, 5, 5] }
    );

    let doc;
    for await (const inpnFeatureInfo of userInpnFeaturesInfos) {
      if (!doc) {
        doc = new jsPDF({
          unit: 'mm',
        });
      } else {
        doc.addPage();
      }

      await this.setSitePdfpage(doc, inpnFeatureInfo);
    }

    // Restore map size and view extent
    this.setMapSizeAndExtent([currentWidth, currentHeight], currentExtent, {
      render: true,
    });

    doc.save(`hvc_${new Date().toLocaleDateString()}.pdf`);
    this.exportDialog.hide();
  }

  setMapSizeAndExtent(size, extent, opts) {
    this.map.setSize(size);

    if (opts.render) {
      this.map.renderSync();
    }

    const viewOpts = {
      size: size,
    };

    if (opts.padding) {
      viewOpts.padding = opts.padding;
    }

    this.view.fit(extent, viewOpts);
  }

  getHvcLogoImg() {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve(img);
      };
      img.onerror = reject;
      img.src = require('../assets/logo_hvc.png');
    });
  }

  async loadHvcLogo() {
    this.hvcLogo = await this.getHvcLogoImg();
  }

  async setSitePdfpage(pdfDoc, inpnFeatInfo) {
    let height = 15;

    pdfDoc.addImage(this.hvcLogo, 'PNG', 75, height);

    height += 15;

    pdfDoc.setFontSize(30);
    pdfDoc.setFillColor('#285c4d');
    pdfDoc.rect(this.horizontalMargin, height, this.contentWidth, 20, 'FD');
    pdfDoc.setTextColor('#ffffff');
    pdfDoc.setFont('Helvetica', '');

    height += 13;

    pdfDoc.text('Hautes Valeurs de Conservation', 25, height);

    height += 20;

    pdfDoc.setTextColor('#285c4d');

    pdfDoc.setFontSize(25);

    const siteNameArr = pdfDoc.splitTextToSize(
      inpnFeatInfo.siteName,
      this.contentWidth
    );

    pdfDoc.text(siteNameArr, this.horizontalMargin, height);

    height += 10 * siteNameArr.length;

    pdfDoc.setFontSize(15);
    pdfDoc.setFont('Helvetica', 'Oblique');

    const layerNameArr = pdfDoc.splitTextToSize(
      inpnFeatInfo.layerName,
      this.contentWidth
    );

    pdfDoc.text(layerNameArr, this.horizontalMargin, height);

    height += 7 * layerNameArr.length;

    const mapCanvas = await this.getMapCanvas({
      ...inpnFeatInfo,
    });

    pdfDoc.addImage(
      mapCanvas.toDataURL('image/png'),
      'PNG',
      this.horizontalMargin,
      height,
      this.contentWidth,
      this.mapHeight
    );

    height += this.mapHeight + 10;

    pdfDoc.setFontSize(12);
    pdfDoc.setFont('Helvetica', 'Bold');
    const inpnSheetTxt = 'Fiche INPN :';

    pdfDoc.text(inpnSheetTxt, this.horizontalMargin, height);

    pdfDoc.setFont('Helvetica', 'Oblique');
    pdfDoc.setTextColor('#78be20');
    pdfDoc.textWithLink(
      inpnFeatInfo.sitePdfUrl,
      this.horizontalMargin + pdfDoc.getTextWidth(inpnSheetTxt) + 2,
      height,
      { url: inpnFeatInfo.sitePdfUrl }
    );

    height += 8;

    if (inpnFeatInfo.species.length > 0) {
      pdfDoc.setFontSize(12);
      pdfDoc.setTextColor('#285c4d');
      pdfDoc.setFont('Helvetica', 'Bold');
      pdfDoc.text(
        'Espèces prioritaires présentes :',
        this.horizontalMargin,
        height
      );

      height += 4;

      pdfDoc.autoTable({
        tableWidth: 170,
        showHead: 'firstPage',
        startY: height,
        margin: {
          top: 0,
          right: this.horizontalMargin,
          bottom: 0,
          left: this.horizontalMargin,
        },
        body: inpnFeatInfo.species.map((specie) => {
          return {
            name: specie.name,
            vernacular_name: specie.vernacular_name,
            priority: specie.priority,
          };
        }),
        columns: [
          { header: 'Nom scientifique', dataKey: 'name' },
          { header: 'Nom français', dataKey: 'vernacular_name' },
          { header: 'Priorité', dataKey: 'priority' },
        ],
        columnStyles: {
          name: {
            fontStyle: 'italic',
          },
        },
        headStyles: {
          fillColor: '#285c4d',
          fontSize: 11,
        },
      });

      height = pdfDoc.autoTable.previous.finalY + 7;
    }

    if (inpnFeatInfo.habitats.length > 0) {
      pdfDoc.setFontSize(12);
      pdfDoc.setTextColor('#285c4d');
      pdfDoc.setFont('Helvetica', 'Bold');

      pdfDoc.text('Habitats présents :', this.horizontalMargin, height);

      height += 4;

      pdfDoc.autoTable({
        tableWidth: 170,
        showHead: 'firstPage',
        startY: height,
        margin: { top: 0, right: 0, bottom: 0, left: this.horizontalMargin },
        body: inpnFeatInfo.habitats.map((inpnFeatInfo) => [
          inpnFeatInfo.lb_hab,
        ]),
      });
    }
  }

  getMapCanvas(opts) {
    return new Promise((resolve) => {
      this.map.once('rendercomplete', () => {
        const mapCanvas = document.createElement('canvas');
        mapCanvas.width = this.imgWidth;
        mapCanvas.height = this.imgHeight;

        const mapContext = mapCanvas.getContext('2d');

        Array.prototype.forEach.call(
          document.querySelectorAll('.ol-layer canvas'),
          (canvas) => {
            if (canvas.width > 0) {
              const opacity = canvas.parentNode.style.opacity;
              mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
              const transform = canvas.style.transform;
              // Get the transform parameters from the style's transform matrix
              const matrix = transform
                .match(/^matrix\(([^(]*)\)$/)[1]
                .split(',')
                .map(Number);

              // Apply the transform to the export map context
              CanvasRenderingContext2D.prototype.setTransform.apply(
                mapContext,
                matrix
              );

              mapContext.drawImage(canvas, 0, 0);
            }
          }
        );

        opts.feature.setStyle(opts.layer.getStyle());

        resolve(mapCanvas);
      });

      opts.feature.setStyle(getHighlightStyle(opts.layer, 'CC', opts.siteName));

      this.map.renderSync();
    });
  }
})();
