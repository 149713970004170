import { register } from 'ol/proj/proj4';
import { get as getProj } from 'ol/proj';

import proj4 from 'proj4';

proj4.defs(
  'EPSG:2154',
  '+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
);
register(proj4);

const proj2154 = getProj('EPSG:2154');
const proj3857 = getProj('EPSG:3857');
const proj4326 = getProj('EPSG:4326');

export { proj2154, proj3857, proj4326 };
