import { Fill, Stroke, Style, Text, Circle } from 'ol/style';

import { stringDivider } from './utils';

function getLayerStyle(hexColor) {
  return new Style({
    stroke: new Stroke({
      color: hexColor,
      width: 2,
    }),
    fill: new Fill({
      color: '#FFFFFF33',
    }),
    image: new Circle({
      radius: 7.5,
      fill: new Fill({
        color: hexColor,
      }),
      stroke: new Stroke({
        color: '#fff',
        width: 1,
      }),
    }),
  });
}

function getHighlightStyle(layer, transparencyHex, siteName) {
  const strokeStyle = layer.getStyle().getStroke();

  let style = new Style({
    stroke: strokeStyle,
    fill: new Fill({
      color: `${strokeStyle.getColor()}${transparencyHex}`,
    }),
  });

  if (siteName) {
    style = [
      style,
      new Style({
        text: new Text({
          font: '12px Calibri,sans-serif',
          overflow: true,
          fill: new Fill({
            color: '#000',
          }),
          stroke: new Stroke({
            color: '#fff',
            width: 3,
          }),
          text: stringDivider(siteName, 20, '\n'),
        }),
        geometry: (feature) => {
          let featGeom = feature.getGeometry();

          if (featGeom.getType() === 'MultiPolygon') {
            [featGeom] = featGeom
              .getPolygons()
              .sort((a, b) => b.getArea() - a.getArea());
          }

          return featGeom.getInteriorPoint();
        },
      }),
    ];
  }

  return style;
}

export { getLayerStyle, getHighlightStyle };
