import GML2 from 'ol/format/GML2';
import { bbox as bboxStrategy } from 'ol/loadingstrategy';
import { transformExtent } from 'ol/proj';
import KML from 'ol/format/KML';
import GeoJSON from 'ol/format/GeoJSON';
import VectorSource from 'ol/source/Vector';

import axios from 'axios';

import { getSiteId } from './utils';
import { proj3857, proj2154, proj4326 } from './proj';

function getWfsSrc(featType) {
  const vectorSrc = new VectorSource({
    strategy: bboxStrategy,
    attributions: "© <a href='https://inpn.mnhn.fr/' target='_blank'>INPN</a>",
  });

  vectorSrc.setLoader((extent) => {
    axios
      .get(`${process.env.PROXY_URL}/WFS/119/fxx_inpn`, {
        params: {
          typename: `ms:${featType}`,
          bbox: transformExtent(extent, proj3857, proj2154).join(','),
          request: 'GetFeature',
        },
      })
      .then((response) => {
        vectorSrc.addFeatures(
          new GML2()
            .readFeatures(
              new DOMParser().parseFromString(response.data, 'text/xml'),
              {
                featureProjection: proj3857,
                dataProjection: proj2154,
              }
            )
            .filter((feature) => {
              return !vectorSrc
                .getFeatures()
                .map((currentFeat) => getSiteId(currentFeat))
                .includes(getSiteId(feature));
            })
        );
      })
      .catch((error) => {
        console.log(error);
        vectorSrc.removeLoadedExtent(extent);
      });
  });

  return vectorSrc;
}

const kmlSrc = new VectorSource({
  format: new KML({ extractStyles: false }),
});

const geojsonSrc4326 = new VectorSource({
  format: new GeoJSON({ dataProjection: proj4326 }),
});

const geojsonSrc2154 = new VectorSource({
  format: new GeoJSON({ dataProjection: proj2154 }),
});

export { getWfsSrc, kmlSrc, geojsonSrc4326, geojsonSrc2154 };
