function getSiteId(feature) {
  return feature
    ? feature.get(
      feature.getKeys().find((key) => ['SITECODE', 'ID_MNHN'].includes(key))
    )
    : null;
}

function getSiteName(feature) {
  return feature.get(
    feature.getKeys().find((key) => ['SITENAME', 'NOM'].includes(key))
  );
}

function stringDivider(str, width, spaceReplacer) {
  if (str.length > width) {
    let p = width;

    while (p > 0 && str[p] !== ' ' && str[p] !== '-') {p--;}

    if (p > 0) {
      let left;
      if (str.substring(p, p + 1) === '-') {left = str.substring(0, p + 1);} else {left = str.substring(0, p);}

      const right = str.substring(p + 1);
      return left + spaceReplacer + stringDivider(right, width, spaceReplacer);
    }
  }
  return str;
}

export { getSiteId, stringDivider, getSiteName };
