import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

import { getLayerStyle } from './styles';
import { getWfsSrc } from './sources';

const layerMinZoom = 11;

const selectedLayer = new VectorLayer({
  displayInLayerSwitcher: false,
  source: new VectorSource({
    attributions:
      "| <a style='color:#DC592A !important;' href='https://www.sylvamap.fr/' target='_blank'>Sylvamap</a>",
  }),
});

const znieff1Color = '#DC592A';
const znieff1Layer = new VectorLayer({
  source: getWfsSrc('znieff1'),
  style: getLayerStyle(znieff1Color),
  minZoom: layerMinZoom,
  properties: {
    title: `<span style="color:${znieff1Color}">ZNIEFF 1<span/>`,
    fullName:
      "Zones naturelles d'intérêt écologique faunistique et floristique (ZNIEFF) type I",
  },
});

const sicColor = '#285C4D';
const sicLayer = new VectorLayer({
  source: getWfsSrc('Sites_d_importance_communautaire_JOUE__ZSC_SIC_'),
  style: getLayerStyle(sicColor),
  minZoom: layerMinZoom,
  properties: {
    title: `<span style="color:${sicColor}">NATURA 2000 (Directive Habitats)</span>`,
    fullName: 'Sites NATURA 2000 (Directive Habitats)',
  },
});

const zpsColor = '#78BE20';
const zpsLayer = new VectorLayer({
  source: getWfsSrc('Zones_de_protection_speciale'),
  style: getLayerStyle(zpsColor),
  minZoom: layerMinZoom,
  properties: {
    title: `<span style="color:${zpsColor}">NATURA 2000 (Directive Oiseaux)</span>`,
    fullName: 'Sites NATURA 2000 (Directive Oiseaux)',
  },
});

const userLayer = new VectorLayer({
  displayInLayerSwitcher: false,
  style: getLayerStyle('#4F87D1'),
  updateWhileAnimating: true,
});

export {
  selectedLayer,
  znieff1Layer,
  sicLayer,
  zpsLayer,
  userLayer,
  layerMinZoom,
};
